import { useWeb3React } from '@web3-react/core';
import Timer from 'components/timer/Timer';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { purchase } from 'utils/contracts';
import './mint.scss'
export default function Mint() {
    const [mintShow, setMintShow] = useState(false);
    const [mintHowMany, setMintHowMany] = useState(false);
    const [count, setCount] = useState(1);
    const inputEl = useRef<HTMLInputElement>(null);
    const clickHandle = () => {
        if (inputEl && inputEl.current) {
            inputEl.current.select();
            document.execCommand('copy');
        }
    }
    const clickReturnHandle = () => {
        setMintHowMany(false)
        setMintShow(false)
    }
    const handleCountChange = (value: any) => {
        setCount(value)
    }

    const [loginStatus, setLoginStatus] = useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();


    const mintTokens = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        // if (mintCount <= 0) {
        //     toast.error("Mint Count should be over than 0");
        //     return;
        // }
        const load_toast_id = toast.loading("Please wait for Mint...");
        try {
            const bSuccess = await purchase(chainId, library.getSigner(), account, 1);
            if (bSuccess) {
                toast.success("Mint Success!");
            } else {
                toast.error("Mint Failed!");
            }
        } catch (error) {
            toast.error("Mint Failed!");
        }
        toast.dismiss(load_toast_id);
    };

    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId]);
    const [showMint, setShowMint] = useState(false)

    return (
        <div className="mint" id="mint">
            <div className="welcome">
                <div className="welcomeContent">
                    <h2>WELCOME</h2>
                    <p>PixelShibs is a collection of 10,000 pixelated elements distributed on the Ethereum blockchain that are inspired by the fantastic Shiba Inu token (SHIB). Each PixelShib is unique and has wonderful traits. These beautiful puppies are lovingly created for the entire Shiba Inu community. Get yours and be part of this limited and fascinating collection of NFTs (Non-Fungible Tokens).</p>

                    {
                        showMint === true? 
                    <div className="mintButton" onClick={mintTokens}>
                        <div className="line1">
                            <div className="line2" >
                                <div className="line3">
                                    <button>Mint Now</button>
                                </div>
                            </div>
                        </div>
                    </div>:
                    <div className="countDown">
                        <div className="line1">
                            <div className="line2" >
                                <div className="line3">
                                    <p>December 15th</p>
                                <Timer setShowMint = {setShowMint}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </div>
            <div className="mintContent">
                <div className="top">
                    <div className="mintLeft">
                        <img src="assets/Illustration 1 1.png" alt="" />
                    </div>
                    <div className="mintRight">
                        <p><span>MINT PIXELSHIBS</span></p>
                        <p>AND HELP US BURN</p>
                        <img src="assets/shiba1 1.png" alt="" />
                    </div>
                </div>
                <div className="bottom">
                    <img src="assets/baner 1400x400 1.png" alt="" />
                </div>
            </div>
        </div>
    )
}

