import { useState } from "react";
import "./faq.scss"

export default function Faq() {
    const faqData = [
        {
            id: 1,
            quetion: "What is a PixelShib?",
            answer: "It is a wonderful pixelated piece of art turned into an NFT. This Cryptoactive is inspired by the SHIB token. Each PixelShib is fantastic, as it has unique features. There are no two alike in the world. While all PixelShibs are cool, some are cooler than others."
        },
        {
            id: 2,
            quetion: "What can I do with a PixelShib?",
            answer: "A PixelShib is a cryptoactive that has value. This can be minted, sold, or bought. It can also be gifted to someone who has an ETH address."
        },
        {
            id: 3,
            quetion: "How can I be part or be aware of this extraordinary community?",
            answer: "Joining our Twitter, Discord and Telegram, as well as frequently checking the PixelShibs' website. Through these digital platforms we will keep you informed about all the news related to PixelShibs."
        },
        {
            id: 4,
            quetion: "How can I find out who will be the winners of the PixelShibs given away and their delivery date?",
            answer: "The winners and delivery date of the PixelShibs provided will be notified via our community on Twitter, Discord and Telegram. A total of 50 PixelShibs will be distributed to the winning owners periodically according to our roadmap."
        },
        {
            id: 5,
            quetion: "How can I know how many SHIB tokens will be burned and on what date?",
            answer: "The amount of SHIB equivalent to 12 ETH will be burned within the times set out in our roadmap. Please note that the amount of SHIB acquired will depend on the price of the cryptocurrencies at the time of the exchange and the fees charged. The amount and evidence of the burned SHIB will be reported through our community on Twitter, Discord and Telegram."
        },
        {
            id: 6,
            quetion: "When will I be able to get a PixelShib from the next collection and how?",
            answer: "Once 100% of the available tokens in this collection are minted, the PixelShibs team will start working on a new one. When it's ready, PixelShibs owners will be notified via our Twitter, Discord and Telegram community of the date of the VIP sale and detailed instructions on how to participate. Only PixelShibs owners can participate in the VIP sale. If you do not own one of our NTFs or were unable to mint one during the VIP sale, you will have the opportunity to acquire them through pre-sale and public sale."
        },
        {
            id: 7,
            quetion: "What is an NFT?",
            answer: "It is a cryptoactive that is unique, it cannot be modified, and it cannot be exchanged for another of equal value. It is based on blockchain technology that allows its traceability and makes it cannot be forged or hacked. That same technology is what allows its creation, purchase, and sale."
        },
    ]

    const [faqId, setFaqId] = useState(-1);
    const handleClick = (id: number) => {
        if (faqId !== -1) {
            if (faqId === id) {
                setFaqId(-1)
            }
            else {
                setFaqId(id)
            }
        }
        else {
            setFaqId(id)
        }

    }
    return (
        <div className="faq" id="faq">
            <div className="faqContent">
                <div className="faqTitle">
                    <h1>FAQ</h1>
                </div>
                <div className="faqWrapper">
                    <div className="line" key={0} >
                        <div className="question" onClick={() => { handleClick(0) }} >
                            <p>Key information</p><i className="show fas fa-chevron-right" style={{ transform: faqId === 0 ? "rotate(90deg)" : "rotate(0deg)" }}></i></div>
                        <div className="answer" style={{ height: faqId === 0 ? "auto" : "0" }}>
                            <div><p className="left">Token name : </p><p className="right"> PixelShibs</p></div>
                            <div><p className="left">Token symbol : </p><p className="right">PIXELSHIB </p></div>
                            <div><p className="left">Total amount of tokens : </p><p className="right"> 10,000</p></div>
                            <div><p className="left">Amount of tokens minted per transaction : </p><p className="right"> 1-20</p></div>
                            <div><p className="left">Pre-sale date : </p><p className="right"> December 15, 2021</p></div>
                            <div><p className="left">Pre-sale price : </p><p className="right"> 0.048 ETH</p></div>
                            <div><p className="left">Public sale date : </p><p className="right">December 16, 2021 </p></div>
                            <div><p className="left">Public sale price : </p><p className="right"> 0.06 ETH</p></div>
                            <div><p className="left">Token distribution : </p><p className="right"> Randomly</p></div>
                            <div><p className="left">Supported wallets : </p><p className="right">MetaMask and WalletConnect. </p></div>
                            <div><p className="left">Tokens withheld from the sale : </p><p className="right"> 100 PixelShibs (gifts, marketing, and team)</p></div>
                            <div><p className="left">Gifted tokens : </p><p className="right">50 PixelShibs </p></div>
                            <div><p className="left">Burned tokens : </p><p className="right">12 ETH in SHIB tokens </p></div>
                            <div><p className="left">Token type : </p><p className="right"> ERC-721</p></div>
                            <div><p className="left">Blockchain : </p><p className="right"> Ethereum</p></div>
                            <div><p className="left">File hosting : </p><p className="right">IPFS </p></div>
                            <div><p className="left">Royalties fees : </p><p className="right"> 3%</p></div>
                            <div><p className="left">Types of traits : </p><p className="right">Background, skin, clothes, paws, mouth, eyes, and hat </p></div>
                            <div><p className="left">Number of traits : </p><p className="right"> 266</p></div>
                            <div><p className="left">Trait assignment mode : </p><p className="right">Randomly assigned by computer program </p></div>
                            <div><p className="left">Licence : </p><p className="right"> Commercial rights</p></div>
                            <div><p className="left">Reveal type : </p><p className="right"> 24-hour reveal</p></div>
                            <div><p className="left">Verified smart contract : </p><p className="right">0xaa6f8C5762222857b4872244B4C83Bf50d4447Cb</p></div>
                        </div>

                    </div>
                    {faqData.map((d) => (
                        <div className="line" key={d.id} >
                            <div className="question" onClick={() => { handleClick(d.id) }} >
                                <p>{d.quetion}</p><i className="show fas fa-chevron-right" style={{ transform: d.id === faqId ? "rotate(90deg)" : "rotate(0deg)" }}></i></div>
                            <div className="answer" style={{ height: d.id === faqId ? "7rem" : "0" }}><p>{d.answer}</p></div>

                        </div>

                    ))}
                    <div className="line" key={8} >
                        <div className="question" onClick={() => { handleClick(8) }} >
                            <p>What is MetaMask and WalletConnect?</p><i className="show fas fa-chevron-right" style={{ transform: faqId === 8 ? "rotate(90deg)" : "rotate(0deg)" }}></i></div>
                        <div className="answer" style={{ height: faqId === 8 ? "7rem" : "0" }}>
                            <p>MetaMask it is a crypto wallet that connects to the Ethereum blockchain and that allows, among other things, to buy and store an NFT. On the other hand, WalletConnect is an open-source protocol that allows mobile wallets to connect securely to a decentralized application (Dapp). For more information visit: <a href="https://metamask.io/">https://metamask.io/</a>  and <a href="https://walletconnect.com/">https://walletconnect.com/</a></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
