import './story.scss'

export default function Story() {
    return (
        <div className="story" id = "story">
            <h1>STORY</h1>
            <p>In a universe other than ours, there is a planet that is ruled by an intelligent civilization called the Shiba Inu. The puppies possess an incredible technological development and a very advanced and effective interplanetary government system. Their finances are fully decentralized, and they love all cryptocurrencies and tokens, especially Ether (ETH) and SHIB. They love to dress stylishly and live radically, just as they love junk food, beers, video games, walking their pets (cute creatures called humans), but most of all, they can't resist taking photos. The Shiba Inu lived in total peace and harmony until the day when something came from the stars that would change their lives forever.</p>
            <p>An alien of unknown origin and with an identification on his suit that said, "Collector #725", entered the atmosphere of this planet in a spaceship. The spaceship had a kind of camouflage that prevented its detection by Shiba Inu surveillance systems. He hid and settled on the planet, and little by little he was studying and learning more about this canine civilization. Upon discovering the inability of the Shiba Inu to resist taking photos, he devised a macabre plan to capture them. </p>
            <p>Anonymously, he rented an arcade establishment that was abandoned. He enabled it and introduced wonderful new machines that will grab the attention of these adorable dogs. One of these machines, the PixelShibs Photo Booth, was the one that would be in charge of capturing and converting the Shiba Inu into a kind of strange technology called NFTs (which we assume means Not Fun be Trapped).</p>
            <p>On the opening night of the arcade, the 10,000 puppies arrived could not resist the PixelShibs Photo Booth machine and lined up to use it. After the closing of the arcade, all the Shiba Inu attending the event were captured and turned into NFTs. Most of the dogs were caught without problems, while a few others fused their DNA with some creatures or materials collected through different universes by Collector #725. After that, the alien left the planet with the collection of the captured dogs, which he called PixelShibs, and went to deep space to commercialize them.</p>
        </div>
    )
}
