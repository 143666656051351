import './homePage.scss'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useEagerConnect } from "hooks/useEagerConnect";
import { Toaster } from 'react-hot-toast';
import Topbar from 'components/topbar/Topbar';
import Menu from 'components/menu/Menu';
import Home from 'components/home/Home';
import Mint from 'components/mint/Mint';
import Story from 'components/story/Story';
import Tokenomics from 'components/tokenomics/Tokenomics';
import Traits from 'components/traits/Traits';
import RoadMap from 'components/roadmap/RoadMap';
import Faq from 'components/faq/Faq';
import Footer from 'components/footer/Footer';
import Team from 'components/team/Team';
export default function HomePage() {
    const [menuOpen, setMenuOpen] = useState(false);
    // Mint count down part
    const [showMint, setShowMint] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: 'screen and (max-width: 768px) and (orientation:portrait)' })
    const isLandOrMobile = useMediaQuery({ query: 'screen and (max-height: 768px) and (orientation:landscape)' })
  
    useEffect(() => {
      if (isTabletOrMobile) {
      }
  
      if (isLandOrMobile) {
      }
      if (!isLandOrMobile && !isTabletOrMobile) {
        setMenuOpen(false)
      }
  
    },[isTabletOrMobile, isLandOrMobile, setMenuOpen]);
  
    useEagerConnect();
    return (
        <>
      <Toaster
        position="top-center"
        toastOptions={{
          success: { duration: 3000 },
          error: { duration: 3000 },
        }}
      />
        <div className="homePage" style={{ backgroundImage: `url("assets/Pixelshibs.jpg")` }}>
        <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <Home />
        <Mint />
        <Story />
        <Tokenomics />
        <Traits />
        <RoadMap />
        <Faq />
        <Team />
        <Footer />
      </div>
    </>
    )
}
