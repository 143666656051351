import './termsfooter.scss'

export default function TermsFooter() {
    return (
        <div className="termsfooter">
            <div className="termsfooterContent">
                <img src="assets/Cryptopia-03 1.png" alt="" />
                <p>© 2021 Cryptopia, LLC</p>
                <p><span><a href="/terms">Terms & Conditions</a></span></p>
            </div>
            <div className="website">
                <h1>TERMS & CONDITIONS</h1>
                <p>Last update: December 1, 2021. </p>
                <p>Publication date: December 1, 2021. </p>
            </div>
        </div>
    )
}
