import './roadmap.scss'
export default function RoadMap() {
    return (
        <div className="roadmap" id ="roadmap">
            <div className="roadmapTitle">
                <h1>ROADMAP</h1>
                <div className="line"></div>
            </div>
            <div className="roadmapContent">
                <div className="left">
                    <div className="item"></div>
                    <div className="item line" >
                        <p>•	6 ETH will be used for the purchase and burning of SHIB tokens.</p>
                    
                    </div>
                    <div className="item smaller"></div>
                    <div className="item line">
                        <p>•	6 ETH will be used for the purchase and burning of SHIB tokens.</p>
                    
                    </div>
                    <div className="item last">
                        <img src="assets/Illustration 2 1.png" alt="" />
                    </div>
                </div>
                <div className="middle">
                    <img src="assets/Frame.png" alt="" />
                </div>
                <div className="right">
                <div className="item bigger"></div>
                    <div className="item line">
                        <p>•	20 PixelShibs will be randomly given away to owners of the NFTs.</p>
                    
                    </div>
                    <div className="item"></div>
                    <div className="item line">
                        <p>•	15 PixelShibs will be randomly given away to owners of the NFTs.</p>
                    
                    </div>
                    <div className="item smaller"></div>
                    <div className="item line last">
                        <p>•	15 PixelShibs will be randomly given away to owners of the NFTs.</p>
                        <p>•	A new and totally revamped collection of PixelShibs will be created. All PixelShibs owners will be able to participate in the VIP sale of this new collection for 24 hours before the official pre-sale. </p>
                    </div>
                </div>
            </div>
            <div className="roadmapContent_mob">
                <div className="left">
                    <div className="phase">
                        <div className="circle1">
                            <div className="circle2">
                                <p>19%</p><p>Sold</p>
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="circle1">
                            <div className="circle2">
                                <p>40%</p><p>Sold</p>
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="circle1">
                            <div className="circle2">
                                <p>60%</p><p>Sold</p>
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="circle1">
                            <div className="circle2">
                                <p>80%</p><p>Sold</p>
                            </div>
                        </div>
                    </div>
                    <div className="phase">
                        <div className="circle1">
                            <div className="circle2">
                                <p>100%</p><p>Sold</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="item line">
                        <p>•	6 ETH will be used for the purchase and burning of SHIB tokens.</p>
                    </div>
                    <div className="item line">
                        <p>•	6 ETH will be used for the purchase and burning of SHIB tokens.</p>
                    </div>
                    <div className="item line">
                        <p>•	20 PixelShibs will be randomly given away to owners of the NFTs.</p>
                    </div>
                    <div className="item line">
                        <p>•	15 PixelShibs will be randomly given away to owners of the NFTs.</p>
                    
                    </div>
                    <div className="item last line">
                        <p>•	15 PixelShibs will be randomly given away to owners of the NFTs.</p>
                        <p>•	A new and totally revamped collection of PixelShibs will be created. All PixelShibs owners will be able to participate in the VIP sale of this new collection for 24 hours before the official pre-sale. </p>
                    </div>
                </div>
                    
            </div>
        </div>
    )
}
