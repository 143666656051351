import "@ethersproject/shims"
import { BigNumber, ethers } from "ethers";
import { getContractObj } from ".";
import { NFTMintEngineDetail } from "./typs";

export async function purchase(chainId, provider, account, numberOfTokens) {
    const PixelShibsContract = getContractObj('PixelShibs', chainId, provider);
    try {
        const ownerAddress: string = await PixelShibsContract.owner();
        var nftPrice: BigNumber = BigNumber.from(0);

        if (ownerAddress.toLocaleLowerCase() === account.toLocaleLowerCase()) {
            nftPrice = BigNumber.from(0);
        } else {
            nftPrice = await PixelShibsContract.PUBLIC_PRICE();
        }

        const tx = await PixelShibsContract.purchase(numberOfTokens, {
            value: nftPrice.mul(numberOfTokens)
        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function getEngineInfo(chainId, library) {
    const PixelShibsContract = getContractObj('PixelShibs', chainId, library);
    try {
        const [
            totalSupply,
            maxSupply,
            ownerAddress
        ] = await Promise.all([
            PixelShibsContract.totalSupply(),
            PixelShibsContract.PIXELSHIB_MAX(),
            PixelShibsContract.owner(),
        ]);

        const nftMintDetail: NFTMintEngineDetail = {
            totalSupply: parseInt(totalSupply.toString()),
            maxSupply: parseInt(maxSupply.toString()),
            ownerAddress: ownerAddress.toString(),
        }

        return nftMintDetail;
    } catch (e) {
        console.log(e);
        return null;
    }
}