import './team.scss'
export default function Team() {
    return (
        <div className="team" id="team">
            <h1>TEAM</h1>
            <div className="teamContent">
                <div className="item" style={{backgroundImage:`url("assets/Group 427321940.png")`}}>
                    <h1>Collector #725</h1>
                    <p>Intergalactic alien collector of unknown origin that finds life forms and turns them into a type of advanced technology called NFT. He is the creator of the PixelShibs.</p>
                </div>
                <div className="item" style={{backgroundImage:`url("assets/Group 427321940.png")`}}>
                    <h1>PixelShibs Photo Booth</h1>
                    <p>Machine created by Collector #725 to capture the Shiba Inu and turn them into fantastic NFTs.</p>
                </div>
            </div>
        </div>
    )
}
