import "./menu.scss"

type MenuType = {
    menuOpen : boolean;
    setMenuOpen(flag:boolean):void;
};

export default function Menu({menuOpen, setMenuOpen}:MenuType) {
    return (
        <div className={"sidebar " + (menuOpen && "active")}>
            <ul>
                <li onClick = {()=> setMenuOpen(false)} className = {"menuItem2 " + (menuOpen && "active")}>
                    <a href="#mint">MINT</a>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"menuItem3 " + (menuOpen && "active")}>
                    <a href="#story">STORY</a>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"menuItem4 " + (menuOpen && "active")}>
                    <a href="#tokenomics">TOKENOMICS</a>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"menuItem4 " + (menuOpen && "active")}>
                    <a href="#traits">TRAITS</a>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"menuItem4 " + (menuOpen && "active")}>
                    <a href="#roadmap">ROADMAP</a>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"menuItem4 " + (menuOpen && "active")}>
                    <a href="#faq">FAQ</a>
                </li>
                <li onClick = {()=> setMenuOpen(false)} className = {"menuItem4 " + (menuOpen && "active")}>
                    <a href="#team">TEAM</a>
                </li>
            </ul>
        </div>
    )
}

