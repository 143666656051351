import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { truncateWalletString } from 'utils';
import ConnectModal from '../connectModal/ConnectModal';
import './topbar.scss'
type MenuType = {
    menuOpen?: boolean;
    setMenuOpen(flag: boolean): void;
};
export default function Topbar({ menuOpen, setMenuOpen }: MenuType) {
    const [showConnectModal, setShowConnectModal] = useState(false)

    const [loginStatus, setLoginStatus] = useState(false);
    const { connector, library, chainId, account, active } = useWeb3React();
    useEffect(() => {
        const isLoggedin = account && active && chainId === parseInt(process.env.REACT_APP_NETWORK_ID, 10);
        setLoginStatus(isLoggedin);
    }, [connector, library, account, active, chainId]);

    return (
        <div className="topbar">
            <div className="logo">
                <a href="/"><img src="assets/logo.png" alt="" /> PixelShibs</a>
            </div>

            <div className="navList">
                <ul>
                    <li><a href="#mint">MINT</a></li>
                    <li><a href="#story">STORY</a></li>
                    <li><a href="#tokenomics">TOKENOMICS</a></li>
                    <li><a href="#traits">TRAITS</a></li>
                    <li><a href="#roadmap">ROADMAP</a></li>
                    <li><a href="#faq">FAQ</a></li>
                    <li><a href="#team">TEAM</a></li>

                </ul>

            </div>
            <div className="socialLinks">
                <a href="https://etherscan.io/token/0xaa6f8c5762222857b4872244b4c83bf50d4447cb" className="ether"> <img src="assets/ethercan.png" alt="" /></a>
                <a href="https://opensea.io/collection/pixelshibsofficial" className="opensea"> <img src="assets/opensea.svg" alt="" /></a>
                <a href="https://twitter.com/PixelShibs"><i className="fab fa-twitter"></i></a>
                <a href="https://t.me/PixelShibs"><i className="fab fa-telegram"></i></a>
                <a href="https://discord.gg/AaCNTaAdd6"><i className="fab fa-discord"></i></a>

            </div>
            {
                loginStatus ? <button> {truncateWalletString(account)}</button>
                    : <button onClick={() => { setShowConnectModal(true) }}>CONNECT WALLET <img src="assets/metamask.png" alt="" /></button>
            }
            <div className={(menuOpen ? "hamburger active" : "hamburger")} onClick={() => setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
            <ConnectModal showConnectModal={showConnectModal} setShowConnectModal={setShowConnectModal} />
        </div>
    )
}
