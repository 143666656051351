import { useWeb3React } from '@web3-react/core';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { purchase } from 'utils/contracts';
import './terms.scss'
export default function Terms() {
    return (
        <div className="terms" id="terms">
            <div className="welcome">
                <div className="welcomeContent">
                    <h1 className="title">TERMS AND CONDITIONS</h1>
                    <p>These terms and conditions constitute a legally binding agreement (the "Agreement") between you (also referred to herein as "You", "Your" or "User") and PixelShibs ("we" or "us"), governing your purchase of digital artworks (NFTs) running on the Ethereum network as part of the PixelShibs collection offering (the "Offering") and use of this website. By participating in this Offering and using this website, you agree to be bound by these terms and all the terms incorporated herein and all the terms of service.</p>
                    <p>By entering into this Agreement, participating in the Offering, and/or using this website, you expressly acknowledge that you understand this Agreement and accept all its terms. If you do not agree to be bound by the terms and conditions of this Agreement, you may not participate in the Offering or use this website. These terms and conditions may be changed at any time, without notice.</p>
                    <h2>ARTICLE I</h2>
                    <h3>Definitions</h3>
                    <p>1. "Art" means any art, graphics, images, designs, logos, taglines, and drawings that may be associated with an NFT in which you acquire Licensed Rights.</p>
                    <p>2. "Equivalent" means the value, in US Dollars, of the market value of the relevant amount of cryptocurrency on the day of the transaction.</p>
                    <p>3. " PixelShibs" means Cryptopia, LLC.</p>
                    <p>4. "Name and Likeness" means name, nicknames, images, logos, likenesses, marks, copyrights, trade dress colors, trade dress designs, marketing materials and/or all other intellectual property of PixelShibs.</p>
                    <p>5. "NFT" means any blockchain-tracked, non-fungible token.</p>
                    <p>6. "Licensed Rights" with respect to an NFT means your rights to a Licensed NFT of which you are the current rightful licensee and which you acquired from a legitimate source, where proof of such purchase is recorded on the relevant blockchain.</p>
                    <p>7. "Licensed NFT" means an NFT from PixelShibs.</p>
                    <p>8. "Third Party IP" means any third-party patent rights (including, without limitation, patent applications and disclosures), copyrights, trade secrets, trademarks, know-how or any other intellectual property rights recognized in any country or jurisdiction in the world.</p>
                    <p>9. "Experiences" means all in-person and virtual events and rewards organized by PixelShibs and members of its community available to those purchasers of certain Licensed NFTs, including any rewards and gifts presented by PixelShibs to said purchasers.</p>
                    <h2>ARTICLE II</h2>
                    <h3>Ownership and Licensed Rights</h3>
                    <p>1. You acknowledge and agree that PixelShibs (or, as applicable, its licensors) owns all legal right, title, and interest in and to the Art and Name and Likeness, and all intellectual property rights therein.</p>
                    <p>2. The rights that You have in and to the Licensed NFT and Art are limited to those expressly stated in Article II Section 7 of this Agreement.</p>
                    <p>3. PixelShibs reserves all rights and ownership in and to the Licensed NFT, Name and Likeness, and Art not expressly granted to You in Article II of this Agreement.</p>
                    <p>4. All purchases of Licensed NFTs, as well as associated charges, are non-refundable. This no-refund policy shall always apply regardless of Your decision to terminate usage of the Licensed NFT, any disruption to the operations of any components of the Licensed NFT, or any other reason whatsoever.</p>
                    <p>5. By using this website and participating in this Offer, you are accepting sole responsibility for all transactions involving Licensed NFT. You are responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the PixelShibs smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.</p>
                    <p>6. By redeeming or participating in any Experience, you acknowledge that the terms and conditions set forth in this Agreement apply to all Experiences and that attendance at any Experience is conditioned upon your adherence to these terms and conditions. PixelShibs reserves all rights and discretion to transfer any rewards and/or prizes at an Experience.</p>
                    <p>7. Without limiting the foregoing and subject to your continued compliance with this Agreement, PixelShibs grants You an unlimited, worldwide license to use, copy, and display the Licensed NFT for the purpose of creating derivative works based upon the Art ("Commercial Use"). Examples of such Commercial Use would e.g., be the use of the Licensed NFT to produce and sell merchandise products (T-Shirts etc.) that are displaying copies of the Art. Nothing herein shall restrict PixelShibs´ right to use, copy, and display the Licensed NFT for its own Commercial Use.</p>
                    <h2>ARTICLE III</h2>
                    <h3>Website, Royalties, and Roadmap</h3>
                    <p>1. You acknowledge and accept that PixelShibs may at any time and without prior notice add, edit, or delete content on its website. In the event of any content update, the date of the update will be visible on the PixelShibs' website.</p>
                    <p>2. You agree to send PixelShibs a three percent (3%) royalty upon sale of a Licensed NFT.</p>
                    <p>3. The roadmap is available on the PixelShibs´ website. You acknowledge and accept that PixelShibs may without prior notice modify and/or not comply with any term stipulated in the roadmap when it deems it pertinent. If there is any change in the roadmap, PixelShibs will update it on its website.</p>
                    <h2>ARTICLE IV</h2>
                    <h3>Restrictions</h3>
                    <p>1. You agree that you may not, nor permit any third party to do or attempt to do any of the following without express prior written consent from PixelShibs in each case: (i) modify the Licensed NFT, Name and Likeness and/or Art for your Licensed NFT in any way, including, without limitation, the shapes, designs, drawings, attributes, or color schemes; (ii) use the Licensed NFT, Name and Likeness and/or Art from your Licensed NFTs in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others, drugs (including, without limitation, both prescription and non-prescription) or other supplements, death, pornography or other "adult only" or sexually explicit activities, massage parlors, prostitution or any dating or escort activities, weapons or ammunition, denigration or discrimination against individuals based on race, national origin, gender, religion, disability, ethnicity, sexual orientation, gender identity or age, medical conditions and/or political campaigns or causes; or (iii) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Licensed NFT, Name and Likeness and/or Art from Your Licensed NFTs.</p>
                    <p>2. To the extent that the Licensed NFT, Name and Likeness and/or Art associated with Your Licensed NFTs contains Third Party IP You understand and agree as follows: (i) that You will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein; (ii) that, depending on the nature of the license granted from the owner of the Third Party IP, PixelShibs may need to pass through additional terms and/or restrictions on Your ability to use the Art; and (iii) to the extent that PixelShibs informs You of such additional restrictions in writing (email is permissible), You will be responsible for complying with all such restrictions from the date that You receive the notice, and that failure to do so will be deemed a breach of this Agreement.</p>
                    <p>3. The restrictions in this Article IV will survive the expiration or termination of this Agreement.</p>
                    <h2>ARTICLE V</h2>
                    <h3>Termination of the License</h3>
                    <p>1. The Licensed Rights granted to You hereunder shall automatically terminate and all rights shall return to PixelShibs if: (i) at any time You sell, trade, donate, give away, transfer, or otherwise dispose of Your Licensed NFT for any reason except as specially provided in Article II of this Agreement; (ii) You breach any of the Agreement and conditions; (iii) You have a trustee, receiver or similar party appointed for Your property, become insolvent, acknowledge Your insolvency in any manner, make an assignment for the benefit of your creditors, or file a petition of bankruptcy; (iv) You engage in any unlawful business practice related to the Licensed NFT; (v) You initiate any legal actions against any of PixelShibs and/or its members, officers, directors, affiliates, agents, attorneys and employees.</p>
                    <h2>ARTICLE VI</h2>
                    <h3>Disclaimers of Warranties and Limitations of Liability</h3>
                    <p>1. The PixelShibs´ website and its connected services, and all Licensed NFTs are provided "as is" and "as available" without warranties of any kind either express or implied.</p>
                    <p>2. To the fullest extent permissible pursuant to applicable law, PixelShibs disclaims all warranties, express or implied, including, but not limited to, implied warranties of non-infringement, merchantability, and fitness for a particular purpose.</p>
                    <p>3. To the fullest extent permissible by applicable law, in no event shall PixelShibs be liable to you for any personal injury, property damage, lost profits, cost of substitute goods or services, loss of data, loss of goodwill, work stoppage, computer and/or device or technology failure or malfunction, or for any form of direct or indirect damages, and/or any special, incidental, consequential, exemplary or punitive damages based on any causes of action whatsoever related to any NFT, including but not limited to the Licensed NFT, the auction, any technology and/or parties related to the auction, including but not limited to blockchain and Ethereum wallets, and the volatility of the cryptocurrency and NFT markets.</p>
                    <p>4. You agree that this limitation of liability applies whether such allegations are for breach of contract, tortious behavior, negligence, or fall under any other cause of action, regardless of the basis upon which liability is claimed and even if a disclaiming party has been advised of the possibility of such loss or damage, and in any event, to the maximum extent permitted by applicable law, PixelShibs´ total aggregate liability shall not exceed ten percent (10%) of the total sum paid directly by you to PixelShibs for the applicable Licensed NFT.</p>
                    <p>5. You accept the inherent security risks of providing information and dealing online over the internet and agree that we have no liability or responsibility for any breach of security unless it is due to our gross negligence.</p>
                    <p>6. You agree that no liability shall extend individually to the members, officers, directors, affiliates, agents, attorneys, and employees of PixelShibs.</p>
                    <p>7. You expressly waive your right to file a claim, dispute and/or take any legal action against the PixelShibs and/or its members, officers, directors, affiliates, agents, attorneys and employees on a class or collective basis.</p>
                    <p>8. PixelShibs and/or its members, officers, directors, affiliates, agents, attorneys, and employees will not be responsible for any typographic or other website errors.</p>
                    <p>9. If applicable law does not allow all or any part of the above limitation of liability to apply to you, the limitations will apply to you only to the extent permitted by applicable law.</p>
                    <h2>ARTICLE VII</h2>
                    <h3>Assumption of Risk</h3>
                    <p>You agree that You assume the following risks:</p>
                    <p>1. To the extent there is a price or market for a blockchain asset such as an NFT, such markets and prices are extremely volatile, and variations in the price of other digital assets could materially and adversely affect the value of any digital asset(s) You own, including Your Licensed NFT, and there is no guarantee that Your Licensed NFTs will have or retain any value;</p>
                    <p>2. the commercial or market value on a Licensed NFT that You purchase may materially diminish in value as a result of a variety of things such as negative publicity regarding NFTs or cryptocurrency, etc.;</p>
                    <p>3. there are risks associated with using an Internet-native assets (e.g., non-fungible tokens, cryptocurrencies, etc.) including, but not limited to, the risk of hardware, software and Internet connections and/or failures, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your digital "wallet" or elsewhere, and PixelShibs will not be responsible for any of these, however caused;</p>
                    <p>4. PixelShibs does not make any promises or guarantees about the availability of the Licensed NFT or the Art on the Internet or that they will host the Licensed NFT or the Art at any specific location and/or for any specific period of time;</p>
                    <p>5. upgrades to the Ethereum platform, a hard fork or other change in the Ethereum platform, a failure or cessation of Ethereum, or a change in how transactions are confirmed on the Ethereum platform may have unintended, adverse effects on all blockchains using such technologies, including without limitation Licensed NFTs;</p>
                    <p>6. PixelShibs does not make any promises or guarantees related blockchain, Ethereum wallets, or any other third parties related to this auction and each of their applications and/or services, including but not limited to the continued availability of either and/or the protection and/or storage of any data you provide to those parties;</p>
                    <p>7. the risk of losing access to Licensed NFT due to loss of private key(s), custodial error, or purchaser error;</p>
                    <p>8. the risk of mining attacks;</p>
                    <p>9. the risk of hacking, security weaknesses, fraud, counterfeiting, cyber-attacks and other technological difficulties;</p>
                    <p>10. the risk of changes to the regulatory regime governing blockchain technologies, cryptocurrencies, and tokens and new regulations, unfavorable regulatory intervention in one or more jurisdictions or policies any of which may materially adversely affect the use and value of the Licensed NFT;</p>
                    <p>11. the risks related to taxation;</p>
                    <p>12. that NFTs are not legal tender and are not backed by any government; and</p>
                    <p>13. PixelShibs is not responsible for any transaction between you and a third party (e.g., Your transfer of a Licensed NFT from a third party on the so-called "secondary market"), and PixelShibs shall have no liability in connection with any such transaction.</p>
                    <p>14. In addition to assuming all the above risks, you acknowledge that You have obtained sufficient information to make an informed decision to license the Licensed NFT and that You understand and agree that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself. No information on PixelShibs' website nor any communication from the PixelShibs or within PixelShibs' communities should be considered financial advice.</p>
                    <p>15. PixelShibs cannot and does not represent or warrant that any Licensed NFT, or its supporting systems or technology, is reliable, current, or error-free, meets Your requirements, or that defects in the Licensed NFT, or its supporting systems or technology, will be corrected</p>
                    <p>16. You accept and acknowledge that PixelShibs will not be responsible for any communication failures, disruptions, errors, distortions or delays You may experience related to the Offering.</p>
                    <h2>ARTICLE VIII</h2>
                    <h3>Taxes and Fees</h3>
                    <p>1. You understand that Licensed NFTs must be purchased with Ethereum and that PixelShibs has no control over gas fees collected by Ethereum miners.</p>
                    <p>2. You understand that PixelShibs does not have the ability to reverse Ethereum transactions.</p>
                    <p>3. You agree not hold PixelShibs liable for any expenses or fees associated with interacting with our smart contract and/or minting or attempting to mint an NFT on our site.</p>
                    <p>4. PixelShibs is not responsible for determining the withholding, sales, use, value added, transfer or other taxes, together with any interest and penalties imposed with respect thereto ("Taxes"), that may apply to transactions on this Site.</p>
                    <p>5. You agree that you are solely responsible for determining what, if any, Taxes apply to your transactions and to withhold, collect, report, and remit the correct amounts of Taxes to the appropriate taxing authorities.</p>
                    <p>6. Unless otherwise indicated on an applicable invoice, amounts due on this Site are exclusive of sale, use, value added or similar Taxes ("Sales Taxes"). This means that Sales Taxes become your sole responsibility.</p>
                    <h2>ARTICLE IX</h2>
                    <h3>Governing Law</h3>
                    <p>1. This Agreement and all matters related to it and/or any Licensed NFT shall be governed by, construed, and enforced in accordance with the laws of the State of Wyoming, as they are applied to agreements entered and to be performed entirely within Wyoming and without regard to conflict of law principles, except to the extent that law is inconsistent with or preempted by federal law.</p>
                    <h2>ARTICLE X</h2>
                    <h3>Modifications</h3>
                    <p>1. PixelShibs may amend or modify this Agreement from time to time. When PixelShibs makes such amendments or modifications, we will make the updated Agreement available on this website and update the "Last Updated" date at the beginning of the Agreement accordingly. Please check this page periodically for changes. Any changes to this Agreement will apply on the date that they are made and, by way of example, Your continued access to or use of the Licensed NFT and the Art after the Agreement has been updated will constitute your binding acceptance of the updates.</p>
                    <p>2. If you are provided a translation of this Agreement, the original version in English will be used in deciding any issues or disputes which arise under this Agreement.</p>
                    <h2>ARTICLE XI</h2>
                    <h3>Eligibility</h3>
                    <p>1. Participation in the PixelShibs Collection Offering is open only to individuals who have the right and authority to enter into this Agreement, are fully able and competent to satisfy the terms, conditions, and obligations herein and who are using currency that such party is the lawful holder thereof.</p>
                    <p>2. Participation in the PixelShibs Collection Offering is not available to Users who have had their User privileges temporarily or permanently deactivated.</p>
                    <p>3. You may not allow other persons to use your User credentials, and You agree that You are the sole authorized user.</p>
                    <p>4. By becoming a User, you represent and warrant that you are at least 18 years old.</p>
                    <h2>ARTICLE XII</h2>
                    <h3>Indemnity</h3>
                    <p>1. You will defend, indemnify, and hold PixelShibs, including each of their respective affiliates, subsidiaries, parents, successors and assigns, and each of our respective officers, directors, employees, agents, or members, harmless from any claims, actions, suits, losses, costs, liabilities and expenses (including reasonable attorneys' fees) relating to or arising out of your license, sale or possession of the Licensed NFT and/or Your participation in the Offering, including: (1) Your breach of this Agreement or the documents it incorporates by reference; (2) Your violation of any law or the rights of a third party as a result of your own interaction with such third party; (3) any allegation that any materials that You submit to us or transmit in the course of the auction, communications seeking PixelShibs' consent to activities or otherwise, infringe or otherwise violate the copyright, trademark, trade secret or other intellectual property or other rights of any third party; and/or (4) any other activities in connection with the Offering or the Licensed NFT.</p>
                    <p>2. This indemnity shall be applicable without regard to the negligence of any party, including any indemnified person.</p>
                    <h2>ARTICLE XIII</h2>
                    <h3>Severability</h3>
                    <p className="last" >1. If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.</p>
                </div>

            </div>
        </div>
    )
}

