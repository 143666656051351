import './tokenomics.scss'

export default function Tokenomics() {
    return (
        <div className="tokenomics" id="tokenomics">
            <h1>TOKENOMICS</h1>
            <div className="tokenomicsContent">
                <div className="wrapper">
                    <div className="left">
                        <div className="item first">
                            <div className="padding">
                            <div className="itemContent">
                            <h2>Quantity</h2>
                            <p>A maximum quantity of 10,000 PixelShibs can be minted. From that total supply, the PixelShibs team will retain 100 tokens to keep a portion of them, use for marketing and give gifts. It is possible to mint between 1-20 PixelShibs per transaction.</p>
                            </div>
                            </div>
                            
                            
                        </div>
                        <div className="item third">
                        <div className="padding">
                            <div className="itemContent">
                            <h2>Price</h2>
                            <p>PixelShibs can be minted at 0.048 ETH (excluding gas cost) in presale on December 15, 2021, for 24 hours or until the amount of 1,900 PixelShibs allocated for the event is exhausted. The remaining 8,000 PixelShibs will be able to be minted in the public sale scheduled for December 16, 2021, at 0.06 ETH (not including the cost of gas).</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="middle">
                        <img src="assets/Group 1000001756.png" alt="" />
                        <div className="tokenImg">
                            <img src="assets/Illustration 3 1.png" alt="" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="item big">
                            <div className="padding">
                                <div className="itemContent">
                            <h2>Smart contract</h2>
                            <p>Built on the ERC-721 non-fungible token standard within the Ethereum blockchain. Each PixelShib was created by a computer program, which randomly generated each pup from layers and was named randomly to ensure fair distribution. PixelShibs can be purchased by anyone with an ETH address and are transferable and tradable on platforms like Open Sea. After the sale of a PixelShib by one of its owners, the PixelShibs team will receive a 3% royalty.</p>
                            </div>
                            </div>
                        </div>
                        
                        <div className="item last">
                            <div className="padding">
                                <div className="itemContent">
                            <h2>Storage</h2>
                            <p>PixelShibs are stored on the InterPlanetary File System (IPFS) network. This means that they are decentralized and are not found exclusively on a single website or server.</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
