import Home from 'components/home/Home';
import Menu from 'components/menu/Menu'
import Terms from 'components/terms/Terms';
import TermsFooter from 'components/termsfooter/TermsFooter';
import Topbar from 'components/topbar/Topbar'
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import './termsPage.scss'

export default function TermsPage() {
    const [menuOpen, setMenuOpen] = useState(false);
    // Mint count down part
    const isTabletOrMobile = useMediaQuery({ query: 'screen and (max-width: 768px) and (orientation:portrait)' })
    const isLandOrMobile = useMediaQuery({ query: 'screen and (max-height: 768px) and (orientation:landscape)' })
  
    useEffect(() => {
      if (isTabletOrMobile) {
      }
  
      if (isLandOrMobile) {
      }
      if (!isLandOrMobile && !isTabletOrMobile) {
        setMenuOpen(false)
      }
  
    },[isTabletOrMobile, isLandOrMobile, setMenuOpen]);
    return (
        <div className="termsPage" style={{ backgroundImage: `url("assets/Pixelshibs.jpg")` }}>
            <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <Home />
            <Terms/>
            <TermsFooter/>
        </div>
    )
}
