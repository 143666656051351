import './app.scss';
import Faq from './components/faq/Faq';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Mint from './components/mint/Mint';
import Rarity from './components/rarity/Rarity';
import RoadMap from './components/roadmap/RoadMap';
import Team from './components/team/Team';
import Aboutus from './components/aboutus/Aboutus';
import Topbar from './components/topbar/Topbar';
import Menu from './components/menu/Menu';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Story from './components/story/Story';
import Tokenomics from './components/tokenomics/Tokenomics';
import Traits from './components/traits/Traits';

import { Toaster } from "react-hot-toast";
import { useEagerConnect } from "hooks/useEagerConnect";
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import HomePage from 'pages/HomePage/HomePage';
import Terms from 'pages/termsPage/TermsPage';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  // Mint count down part
  const [showMint, setShowMint] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: 'screen and (max-width: 768px) and (orientation:portrait)' })
  const isLandOrMobile = useMediaQuery({ query: 'screen and (max-height: 768px) and (orientation:landscape)' })

  useEffect(() => {
    if (isTabletOrMobile) {
    }

    if (isLandOrMobile) {
    }
    if (!isLandOrMobile && !isTabletOrMobile) {
      setMenuOpen(false)
    }

  });

  useEagerConnect();
  return (
      <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/terms" component={Terms} />
      </Switch>
    </Router>
  );
}

export default App;
