import './traits.scss'

export default function Traits() {
    const traitsData = [
        {
            id: 0,
            cat : 'Background',
            num : 16
        },
        {
            id: 1,
            cat : 'Skin',
            num : 6
        },
        {
            id: 2,
            cat : 'Clothes',
            num : 98
        },
        {
            id: 3,
            cat : 'Paws',
            num : 50
        },
        {
            id: 4,
            cat : 'Mouth',
            num : 34
        },
        {
            id: 5,
            cat : 'Eyes',
            num : 32
        },
        {
            id: 6,
            cat : 'Hat',
            num : 30
        },
    ]
    return (
        <div className="traits" id ="traits">
            <div className="traitsContent" style = {{backgroundImage:`url("assets/Group 1000001765.png")`}}>
                <div className="left">
                    <div className="leftContent">
                        <h1>TRAITS </h1>
                        <p>Each adorable puppy can have up to 7 out of 266 different traits. These are divided into 7 categories that are background, skin, clothes, paws, mouth, eyes, and hat. There are 6 types of skins: original "non-DNA mutated dog", zombie, golden, cyborg, spectral, and cosmic. Although all PixelShibs are wonderful, the rarer their features the more valuable the NTF will be.</p>
                    </div>

                </div>
                <div className="right">
                    <div className="rightContent">
                        <div className="row header">
                            <div className="left">Categories</div>
                            <div className="right">Number of traits</div>
                        </div>
                        {traitsData.map((d)=>(
                            <div className="row">
                                <div className="left">{d.cat} : </div>
                                <div className="right">{d.num}</div>
                            </div>
                        ))}
                        <div className="row last">
                                <div className="left">Total : </div>
                                <div className="right">266</div>
                            </div>
                    </div>
                </div>
            </div>
            <div className="gallery">
                <img src="assets/image 2691.png" alt="" />
                <img src="assets/image 2692.png" alt="" />
                <img src="assets/image 2693.png" alt="" />
                <img src="assets/image 2694.png" alt="" />
                <img src="assets/image 2695.png" alt="" />
                <img src="assets/image 2696.png" alt="" />
                <img src="assets/image 2697.png" alt="" />
                <img src="assets/image 2690.png" alt="" />
            </div>
        </div>
    )
}
