import { useEffect, useState } from 'react';
import Menu from '../menu/Menu';
import Timer from '../timer/Timer';
import Topbar from '../topbar/Topbar'
import './home.scss'
import { useMediaQuery } from 'react-responsive'
export default function Home() {
    const [menuOpen, setMenuOpen] = useState(false);
    // Mint count down part
    const [showMint, setShowMint] = useState(false)
    const isTabletOrMobile = useMediaQuery({ query: 'screen and (max-width: 768px) and (orientation:portrait)' })
    const isLandOrMobile = useMediaQuery({ query: 'screen and (max-height: 768px) and (orientation:landscape)' })
    
    useEffect(() => {
        if (isTabletOrMobile){
        }

        if (isLandOrMobile){
        }
        if (!isLandOrMobile && !isTabletOrMobile){
            setMenuOpen(false)
        }
        
    });
    return (
        <div className="home" id = "home" >
            
            <div className="homeContent">
                <div className="BgImg" style={{backgroundImage:`url("assets/SCENARIO 1.png")`}}>
                </div>
            </div>
        </div>
    )
}
